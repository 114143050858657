
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import { ethers } from 'ethers'
import { IEcosystem, EcosystemId, ECOSYSTEMS, ChainId } from '@/ecosystem'
import SliderTabs from '@/components/SliderTabs.vue'

import {
    ERC20_ABI,
    WETH_ABI,
    PADSWAP_PAIR_ABI,
    SWAP_ROUTER_ABI,
    SWAP_FACTORY_ABI,
    APPROVE_AMOUNT
} from '@/constants'

import {
    WHITELIST,
    DEFAULT_SWAP_ROUTES
} from '@/config/swap_token_whitelist'

import {
  ROUTING_WHITELIST
} from '@/config/routing_token_whitelist'

import { tokenInfo } from '@/mixins/tokenInfo.ts'
import TokenSelector from '@/components/swap/TokenSelector.vue'

const routerAddresses = {
  56: '0x76437234D29f84D9A12820A137c6c6A719138C24', // BNB
  1284: '0x40F1fEF0Fe68Fd10ff904070ee00a7769EE7fe34', // Moonbeam
  1285: '0x790d4b443edB9ce9A8d1aEC585edd89E51132D2c' // Moonriver
}

const padAddresses = {
  56: '0xC0888d80EE0AbF84563168b3182650c0AdDEb6d5',
  1284: '0x59193512877E2EC3bB27C178A8888Cfac62FB32D',
  1285: '0x45488C50184Ce2092756ba7CdF85731fD17e6f3d'
}

const toadAddresses = {
  56: '0x463E737D8F740395ABf44f7aac2D9531D8D539E9',
  1284: '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
  1285: '0x165DBb08de0476271714952C3C1F068693bd60D7'
}

export default Vue.extend({
    components: { 
        TokenSelector,
        SliderTabs
    },
    mixins: [tokenInfo],
    data() {
        return {

          swapRoute: <Array<any>> [], // Array of token addresses describing the swap route
          swapRouteDetails: [], // Array of dictionaries with token data, to show detailed route info in the UI

          isEstimationLoading: <boolean> false,

          selectedField: 'input',

          slippageTolerance: <string> '1',
          transactionDeadlineMinutes: <string> '15',
          priceImpactPercent: <string> '',


          inputToken: <any> {},
          outputToken: <any> {},
          decimalsIn: <number> 18,
          decimalsOut: <number> 18,
          wethAddress: <string> '',

          inputTokenAllowance: <string> '0',
          outputTokenAllowance: <string> '0',

          inputTokenBalanceBn: <ethers.BigNumber> ethers.BigNumber.from(0),
          inputTokenBalance: <string> '0',

          inputAmount: <string> '',
          outputAmount: <string> '',

          swapMode: 0,

          tokenWhitelist: <any> [],

          tokenSelectionDialog: <boolean> false,
          selectedTokenAddress: <string> '',

          routerContractAddress: <string> '0x40F1fEF0Fe68Fd10ff904070ee00a7769EE7fe34'
        }
    },
    created() {
      this.initializeForCurrentChain()
      setInterval(this.updateTokenBalances, 3000)
    },
    computed: {
        ecosystemId: {
          get(): EcosystemId {
            return this.$store.state.ecosystemId
          },
          set(val: EcosystemId) {
            this.$store.commit('setEcosystemId', val)
          }
        },
        userAddress(): string {
          return this.$store.state.address
        },
        multicall(): ethers.providers.Provider {
          return this.$store.getters.multicall
        },
        ecosystem(): IEcosystem {
          return this.$store.getters.ecosystem
        },
        web3(): ethers.Signer | null {
          return this.$store.state.web3
        },
        chainId(): ChainId {
          return this.$store.getters.ecosystem.chainId
        },
        tokensApproved(): boolean {
          if ((parseFloat(this.inputAmount) > 0 && parseFloat(this.inputTokenAllowance) < parseFloat(this.inputAmount))) {
            return false
          }
          else {
            return true
          }
        },
        minimumReceived(): number {
          const minimumPercentage = 1.0 - (parseFloat(this.slippageTolerance) / 100.0)
          const minimumAmount = parseFloat(this.outputAmount) * minimumPercentage
          return minimumAmount
        },
        maximumSold(): number {
          const maximumExtraPercentage = (parseFloat(this.slippageTolerance) / 100.0)
          const maximumAmount = parseFloat(this.inputAmount) + parseFloat(this.inputAmount) * maximumExtraPercentage
          return maximumAmount
        },

        inputAmountBn() : ethers.BigNumber {
          let inputAmount = parseFloat(this.inputAmount.toString())
          let inputAmountBn = ethers.utils.parseUnits(inputAmount.toFixed(this.decimalsIn).toString(), this.decimalsIn)
          if (inputAmountBn.gt(this.inputTokenBalanceBn)) {
            inputAmountBn = this.inputTokenBalanceBn
          }
          return inputAmountBn
        },
        outputAmountBn() : ethers.BigNumber {
          const outputAmount = parseFloat(this.outputAmount.toString())
          return ethers.utils.parseUnits(outputAmount.toFixed(this.decimalsOut).toString(), this.decimalsOut)
        },
        maximumInBn() : ethers.BigNumber {
          const maximumSold = parseFloat(this.maximumSold.toString())
          return ethers.utils.parseUnits(maximumSold.toFixed(this.decimalsIn).toString(), this.decimalsIn)
        },
        minimumOutBn() : ethers.BigNumber {
          const minimumReceived = parseFloat(this.minimumReceived.toString())
          return ethers.utils.parseUnits(minimumReceived.toFixed(this.decimalsOut).toString(), this.decimalsOut)
        },
        txDeadline() : number {
          return Date.now() + 1000 * 60 * parseFloat(this.transactionDeadlineMinutes)
        },
        priceModel(): any {
          return this.$store.getters.ecosystem.priceModel
        },
    },
    watch: {
        inputToken() {
          this.updateTokenBalances()
          this.updateEstimation()
        },
        outputToken() {
          this.updateTokenBalances()
          this.updateEstimation()
        },
        inputAmount() {
          if (this.selectedField == 'input') {
            this.swapMode = 0
            this.updateEstimation()
          }
        },
        outputAmount() {
          if (this.selectedField == 'output') {
            this.swapMode = 1
            this.updateEstimation()
          }
        },
        userAddress() {
          this.updateTokenBalances()
        },
        chainId() { // Re-initializing after changing chain
          this.initializeForCurrentChain()
        }
    },
    methods: {
      // Called on initialization
      // and when switching to another chain
      async initializeForCurrentChain() {
        this.routerContractAddress = routerAddresses[this.chainId]
        this.swapMode = 0
        this.inputAmount = ''
        this.outputAmount = ''
        this.updateTokenWhitelist()
        this.setDefaultRoute()

        const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)
        this.wethAddress = await routerContract.WETH()

        setTimeout(this.updateTokenBalances, 200)

        if (this.chainId == 56) {
          this.setSwapEcosystem("BSC")
        }
        else if (this.chainId == 1285) {
          this.setSwapEcosystem("MOVR")
        }
        else if (this.chainId == 1284) {
          this.setSwapEcosystem("GLMR")
        }
      },

        setSwapEcosystem(chain_id : string) {
          this.routerContractAddress = routerAddresses[this.chainId]
          this.setDefaultRoute()

          const urlParams = new URLSearchParams(window.location.search)
          const [action, token1, token2] = [urlParams.get('action'), urlParams.get('token1'), urlParams.get('token2')]
          let appendParameters = ''
          if(action) {
            appendParameters = `#/${action}/${token1}/${token2}`
          } else {
            const inputCurrency = urlParams.get('inputCurrency')
            const outputCurrency = urlParams.get('outputCurrency')
            if (inputCurrency || outputCurrency) {
              appendParameters = `#/?inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`
            }
          }
          this.swapMode = 0
          this.updateTokenWhitelist()
          this.updateTokenBalances()
          this.updateEstimation()
        },


        toNumber(num : any) {
          if (isNaN(parseFloat(num))) {
            return 0
          }
          return parseFloat(num)
        },


        round(num : any, dec : any) {
          num = Number(num).toFixed(20)
          if(!Number.isFinite(Number(num))) num = '0.0'
          num = Number(num).toFixed(20)
          const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dec}})?`)
          let [int, decimals] = num.toString().replace(',', '.').split('.')
          if(dec == 0) return int
          const rounded = num.toString().match(regex)[0]
          return rounded
        },


        biOrMiOrK(num : number) : string {
          if(num>=1e9) return this.round(num/1e9, 2) + 'BI'
          else if(num>=1e6) return this.round(num/1e6, 2) + 'M'
          else if (num>=1e3) return this.round(num/1e3, 2) + 'K'
          else if (num>= 1e2) return this.round(num, 2)
          else if (num >= 1) return this.round(num, 4)
          else return this.round(num, 6)
        },


        setDefaultRoute() {
            var currentChainDefaults = DEFAULT_SWAP_ROUTES[this.chainId]
            this.inputToken = currentChainDefaults.inputToken
            this.outputToken = currentChainDefaults.outputToken
        },


        updateTokenWhitelist() {
            var currentChainWhitelist = WHITELIST[this.chainId]
            this.tokenWhitelist = currentChainWhitelist
        },


        async updateInputToken(newInputToken : any) {
          if (newInputToken.address == this.outputToken.address) {
            this.outputToken = this.inputToken
          }
          this.inputToken = newInputToken
        },


        async updateOutputToken(newOutputToken : any) {
          if (newOutputToken.address == this.inputToken.address) {
            this.inputToken = this.outputToken
          }
          this.outputToken = newOutputToken
        },


        setMax() {
          this.inputAmount = this.inputTokenBalance
        },


        switchSelectedTokens() {
          var tmp = this.inputToken
          this.inputToken = this.outputToken
          this.outputToken = tmp
        },


        async updateTokenBalances() {
          if (!this.web3){
            this.inputTokenBalance = '0'
            return
          }
          if (this.inputToken.address == 'eth') {
            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

            const ethBalanceBn = await this.multicall.getBalance(this.userAddress)
            const ethBalance = ethers.utils.formatEther(ethBalanceBn)
            this.inputTokenBalanceBn = ethBalanceBn
            this.inputTokenBalance = ethBalance

            this.inputTokenAllowance = 99999999999999999999999999999999999999999999999999999999999.0.toString()
          }
          else {
            let abi = ERC20_ABI
            if (this.inputToken.address == this.wethAddress) {
              abi = WETH_ABI
            }

            const tokenContract = new ethers.Contract(this.inputToken.address, abi, this.multicall)

            const tokenBalanceBn = await tokenContract.balanceOf(this.userAddress)
            const decimals = await tokenContract.decimals()
            const tokenBalance = ethers.utils.formatUnits(tokenBalanceBn, decimals)
            this.inputTokenBalanceBn = tokenBalanceBn
            this.inputTokenBalance = tokenBalance

            const tokenAllowanceBn = await tokenContract.allowance(this.userAddress, this.routerContractAddress)
            this.inputTokenAllowance = ethers.utils.formatUnits(tokenAllowanceBn, decimals)
          }
        },

        async updateEstimation() {
          // More readable representation of what should be estimated
          const tokenToEstimate = (this.swapMode == 0 ? 'output' : 'input')

          // Used for showing the loading status in the UI
          // TODO: ensure that only one instance of this method can be running at a time
          this.isEstimationLoading = true

          // Not doing anything if the input amount is zero
          if (tokenToEstimate == 'output' && parseFloat(0 + this.inputAmount) == 0) {
            this.outputAmount = ''
            this.isEstimationLoading = false
            return
          }
          if (tokenToEstimate == 'input' && parseFloat(0 + this.outputAmount) == 0) {
            this.inputAmount = ''
            this.isEstimationLoading = false
            return
          }

          // Swap router contract
          const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

          // Input and output tokens
          let inputToken = this.inputToken.address
          let outputToken = this.outputToken.address

          // If one of the tokens is the chain's native token, substituting it with the wrapped version
          const weth = await routerContract.WETH()
          if (inputToken == 'eth') {
            inputToken = weth
          }
          if (outputToken == 'eth') {
            outputToken = weth
          }

          // If swapping between the chain's native token and its wrapped version,
          // estimation is not required as it will always be 1:1
          if (inputToken.toLowerCase() == weth.toLowerCase() && outputToken.toLowerCase() == weth.toLowerCase()) {
            this.decimalsIn = 18
            this.decimalsOut = 18
            if (tokenToEstimate == 'input') {
              this.inputAmount = this.outputAmount
            }
            else {
              this.outputAmount = this.inputAmount
            }
            
            this.isEstimationLoading = false
            this.priceImpactPercent = '0'
            return
          }

          // Decimals of input and output tokens
          const decimalsIn = await this.getDecimals(inputToken)
          const decimalsOut = await this.getDecimals(outputToken)
          this.decimalsIn = decimalsIn
          this.decimalsOut = decimalsOut

          // Input/output amounts as numeric strings
          let inputAmount : string = (0 + this.inputAmount).toString()
          let outputAmount : string = (0 + this.outputAmount).toString()

          // Retrieving the actual output amounts from input amount provided by user
          let amountInBn : ethers.BigNumber = ethers.utils.parseUnits(inputAmount, decimalsIn)
          let amountOutBn : ethers.BigNumber = ethers.utils.parseUnits(outputAmount, decimalsOut)

          // Finding the best route
          const isExactInMode = (tokenToEstimate == 'output' ? true : false)
          const tokenAmountBn = (tokenToEstimate == 'output' ? amountInBn : amountOutBn)

          const bestResult = await this.findBestRoute(tokenAmountBn, inputToken, outputToken, isExactInMode)
          this.swapRoute = bestResult.route
          this.getRouteDetails(bestResult.route).then((res : any) => this.swapRouteDetails = res)

          // Parsing the output token amount according to the best route
          if (tokenToEstimate == 'input') {
            amountInBn = bestResult["price"]
            inputAmount = ethers.utils.formatUnits(amountInBn, decimalsIn)
          }
          else if (tokenToEstimate == 'output') {
            amountOutBn = bestResult["price"]
            outputAmount = ethers.utils.formatUnits(amountOutBn, decimalsOut)
          }

          // Calculating price impact and recording the results
          if (tokenToEstimate == 'input') {
            try {
              const smallOutputAmount : number = parseFloat( ( parseFloat(0.0 + this.outputAmount) / 100000.0).toFixed(decimalsOut) )
              const smallOutputAmountBn: ethers.BigNumber = amountOutBn.div(100000)
              const smallAmountsIn = await routerContract.getAmountsIn(smallOutputAmountBn, bestResult["route"])

              // Parsing the resulting input amount with a smaller output amount
              const smallAmountInBn = smallAmountsIn[0]
              const smallInputAmount = ethers.utils.formatUnits(smallAmountInBn, decimalsIn)

              // Calculating the price impact
              const smallInputValue = smallOutputAmount / parseFloat(smallInputAmount)
              const realInputValue = parseFloat(this.outputAmount) / parseFloat(inputAmount)
              const receivedValuePercent = (realInputValue / smallInputValue) * 100.0
              const impactPercent = 100.0 - receivedValuePercent

              this.priceImpactPercent = (impactPercent > 0 ? impactPercent.toFixed(2) : '0')
            }
            catch (err) {
              this.priceImpactPercent = "0.1"
            }

            this.inputAmount = inputAmount.toString()
          }
          else if (tokenToEstimate == 'output') {
            try {
              const smallInputAmount : number = parseFloat( (parseFloat(0.0 + this.inputAmount) / 100000.0).toFixed(decimalsIn) )
              const smallInputAmountBn : ethers.BigNumber = amountInBn.div(100000)
              const smallAmountsOut = await routerContract.getAmountsOut(smallInputAmountBn, bestResult["route"])

              // Parsing the output amount with a smaller input amount
              const smallAmountOutBn = smallAmountsOut[smallAmountsOut.length - 1]
              const smallOutputAmount = ethers.utils.formatUnits(smallAmountOutBn, decimalsOut)

              // Calculating the price impact
              const smallOutputValue = parseFloat(smallOutputAmount) / smallInputAmount
              const realOutputValue = parseFloat(outputAmount) / parseFloat(this.inputAmount)
              const receivedValuePercent = (realOutputValue / smallOutputValue) * 100.0
              const impactPercent = 100.0 - receivedValuePercent

              this.priceImpactPercent = (impactPercent > 0 ? impactPercent.toFixed(2) : '0')
            }
            catch (err) {
              this.priceImpactPercent = "0.1"
            }

            this.outputAmount = outputAmount.toString()
          }

          this.isEstimationLoading = false
        },


        async getDecimals(tokenContractAddress : string) {
          if (tokenContractAddress == 'eth') {
            return 18
          }
          else {
            let tokenContract = new ethers.Contract(tokenContractAddress, ERC20_ABI, this.multicall)
            const decimals = await tokenContract.decimals()
            return decimals
          }
        },


        // Prompts the user to connect the wallet
        async connectWallet() {
          this.$store.dispatch('requestConnect')
        },


        // Calls the appropriate function depending on swap type
        // (tokens for tokens, eth for tokens, etc.)
        async swap() {
          // Swapping the chain's native token for an ERC-20 token
          if (this.inputToken.address == 'eth') {
            if (this.swapMode == 0) {
              this.swapExactETHForTokens()
            }
            else{
              this.swapETHForExactTokens()
            }
          }
          // Swapping an ERC-20 token for the chain's native token
          else if (this.outputToken.address == 'eth') {
            if (this.swapMode == 0) {
              this.swapExactTokensForETH()
            }
            else{
              this.swapTokensForExactETH()
            }
          }
          // Swapping an ERC-20 token for another ERC-20 token
          else {
            if (this.swapMode == 0) {
              this.swapExactTokensForTokens()
            }
            else{
              this.swapTokensForExactTokens()
            }
          }
        },


        async approve() {
          const inputTokenContract = new ethers.Contract(this.inputToken.address, ERC20_ABI, this.multicall)

          const tx = await inputTokenContract.populateTransaction.approve(this.routerContractAddress, APPROVE_AMOUNT)
          await this.safeSendTransaction({ tx, targetChainId: this.ecosystem.chainId})
        },


        //==================================================================//
        // Finds the best possible swap route based on the whitelist        //
        // by estimating the result of every route and finding the best one //
        //==================================================================//
        async findBestRoute(amountBn : ethers.BigNumber, inputTokenAddress : string, outputTokenAddress : string, exactIn : boolean = true) {
          // Swap router contract
          const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

          // Factory contract, inferred from the router contract
          const factoryAddress = await routerContract.factory()
          const factoryContract = new ethers.Contract(factoryAddress, SWAP_FACTORY_ABI, this.multicall)

          // The list of possible routes contains at least two routes: the direct route, and the route through the chain's native token
          const weth = await routerContract.WETH()
          const possibleRoutes = [[inputTokenAddress, outputTokenAddress], [inputTokenAddress, weth, outputTokenAddress]]

          // Populating the list of possible routes with token addresses taken from the routing whitelist
          for (const routingToken of ROUTING_WHITELIST[this.chainId]) {
            possibleRoutes.push([inputTokenAddress, routingToken.address, outputTokenAddress])
          }

          var amountTmp = ethers.utils.formatEther(amountBn)
          amountBn = ethers.utils.parseEther(amountTmp)

          // amountBn = ethers.utils.formatUnits(inputAmount.toFixed(this.decimalsIn).toString()

          // Calculating resulting prices for all routes
          const promises : any = [routerContract.WETH()]
          const results : Array<any> = []
          for (const route of possibleRoutes) {
            if (exactIn == true) { // "Exact input" mode
              const p = routerContract.getAmountsOut(amountBn, route).then((res : any) => {
                try {
                  var amountOut = res[res.length - 1]
                  results.push({
                    "route": route,
                    "price": amountOut
                  })
                }
                catch {}
              })
              promises.push(p)
            }
            else { // "Exact output" mode
              const p = routerContract.getAmountsIn(amountBn, route).then((res : any) => {
                try {
                  var amountIn = res[0]
                  results.push({
                  "route": route,
                  "price": amountIn
                  })
                }
                catch {}
                
              })
              promises.push(p)
            } 
          }

          // This construction is needed to ensure that the code keeps running
          // even if the contract shits itself during one of the estimations
          for (const p of promises) {
            try { await p }
            catch(err) {}
          }

          for (const p of promises) {
            try {
              const res = await p
            }
            catch(err) {}
          }

          // Indentifying the best swap route
          let bestRoute = results[0] // The "default" route is the direct "TokenA->TokenB" route
          for (const result of results) {

            const priceCurrent = ethers.utils.formatEther(bestRoute["price"])
            const priceNew = ethers.utils.formatEther(result["price"])

            if (exactIn == true) { // Looking for highest output if in "exact input" mode
              if (result["price"].gt(bestRoute["price"])) {
                if (parseFloat(priceNew) / parseFloat(priceCurrent) > 1.01) {
                  bestRoute = result
                }
              }
            }
            else { // Looking for lowest input if in "exact output" mode
              if (result["price"] < bestRoute["price"]) {
                if (parseFloat(priceNew) / parseFloat(priceCurrent) < 0.99) {
                  bestRoute = result
                }
              }
            }
          }

          return bestRoute
        },


        //===========================================//
        // Filling the details about the swap route, //
        // to then show this info in the UI          //
        //===========================================//

        async getRouteDetails(swapRoute : Array<string>) {
          const routeDetails = []
          for (const tokenAddress of swapRoute) {
            //@ts-ignore-next-line
            const tokenData = await this.getTokenData(tokenAddress)
            routeDetails.push(tokenData)
          }
          return routeDetails
        },


        //==============================================//
        // Wrapping/unwrapping the chain's native token //
        //==============================================//

        async wrapETH() {
          const wethContract = new ethers.Contract(this.wethAddress, WETH_ABI, this.multicall)

          const tx = await wethContract.populateTransaction.deposit()
          tx.value = this.inputAmountBn

          const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },

        async unwrapETH() {
          const wethContract = new ethers.Contract(this.wethAddress, WETH_ABI, this.multicall)

          const tx = await wethContract.populateTransaction.withdraw(this.inputAmountBn)

          const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },


        //============================//
        // Swapping tokens for tokens //
        //============================//

        async swapExactTokensForTokens() {
            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

            const tx = await routerContract.populateTransaction.swapExactTokensForTokensSupportingFeeOnTransferTokens(
              this.inputAmountBn,
              this.minimumOutBn,
              this.swapRoute,
              this.userAddress,
              this.txDeadline)

            console.log(tx)

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },


        async swapTokensForExactTokens() {
            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)

            const tx = await routerContract.populateTransaction.swapTokensForExactTokens(
              this.outputAmountBn,
              this.maximumInBn,
              this.swapRoute,
              this.userAddress,
              this.txDeadline)

            console.log(tx)

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },



        //=====================================================//
        // Swapping ERC-20 tokens for the chain's native token //
        //=====================================================//

        async swapExactTokensForETH() {
            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)
            const weth = await routerContract.WETH()

            const tx = await routerContract.populateTransaction.swapExactTokensForETHSupportingFeeOnTransferTokens(
              this.inputAmountBn,
              this.minimumOutBn,
              this.swapRoute,
              this.userAddress,
              this.txDeadline)

            console.log(tx)

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },


        async swapTokensForExactETH() {
            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)
            const weth = await routerContract.WETH()

            const tx = await routerContract.populateTransaction.swapTokensForExactETH(
              this.outputAmountBn,
              this.maximumInBn,
              this.swapRoute,
              this.userAddress,
              this.txDeadline)

            console.log(tx)

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },



        //=====================================================//
        // Swapping the chain's native token for ERC-20 tokens //
        //=====================================================//

        async swapExactETHForTokens() {
            const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)
            const weth = await routerContract.WETH()

            const tx = await routerContract.populateTransaction.swapExactETHForTokensSupportingFeeOnTransferTokens(
              this.minimumOutBn,
              this.swapRoute,
              this.userAddress,
              this.txDeadline)

            tx.value = this.inputAmountBn

            console.log(tx)

            const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },


        async swapETHForExactTokens() {
          const routerContract = new ethers.Contract(this.routerContractAddress, SWAP_ROUTER_ABI, this.multicall)
          const weth = await routerContract.WETH()

          const tx = await routerContract.populateTransaction.swapETHForExactTokens(
            this.outputAmountBn,
            this.swapRoute,
            this.userAddress,
            this.txDeadline)

          tx.value = this.maximumInBn

          console.log(tx)

          const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
        },



        //////////////
        // Styling  //
        ///////////////

        // Returns the appropriate page background color depending on the ecosystem
        getBackgroundStyle() {
          let ecosystemBackgrounds = {
            56: "background: radial-gradient(circle, #6a6a6a45 0%, rgba(253, 187, 45, 0) 100%);",
            1284: "background: radial-gradient(circle, #6e00ff40 0%, rgba(253, 187, 45, 0) 100%);",
            1285: "background: radial-gradient(circle, #007eff40 0%, rgba(253, 187, 45, 0) 100%);"
          }

          let backgroundStyle = "opacity: 80%; width: 100%; height: 100%; position: fixed; left: 0; top: 0;" + ecosystemBackgrounds[this.chainId]
          
          return backgroundStyle
        },

        // Returns the appropriate background class for the token selection cards
        getCardStyle() {
          let ecosystemBackgrounds = {
            56: "bg bg-bsc",
            1284: "bg bg-moonbeam",
            1285: "bg bg-moonriver"
          }

          const backgroundClass = ecosystemBackgrounds[this.chainId]
          
          return backgroundClass
        },

        // Returns the price impact color based on its severity
        getPriceImpactStyle() {
          let impactStyles : any = {
            1.0: "color: rgb(49, 208, 170);",
            3.0: "color: white;",
            5.0: "color: rgb(240, 185, 11);"
          }

          // Returning one of the "safe" colors if the price impact is below one of the thresholds
          for (const [key, value] of Object.entries(impactStyles)) {
            if (parseFloat(this.priceImpactPercent) < parseFloat(key)) {
              return value
            }
          }

          // Otherwise the slippage is too high and the color will be red
          return "color: rgb(237, 75, 158);"
        },


        ...mapGetters(['isConnected']),
        ...mapActions(['requestConnect', 'safeSendTransaction'])
    }
})

